<script>
  export default {
    created() {
      // 静的ページにリダイレクト
      window.location.href = '/error.html'
    }
  }
</script>
<template>
  <div></div>
</template>