import { createRouter, createWebHistory } from 'vue-router';

import TopPage from './components/TopPage.vue';
import ErrorPage from './components/ErrorPage.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { name: 'top', path: '/', component: TopPage },
    { path: '/:catchAll(.*)', component: ErrorPage },
  ],
});

export default router;