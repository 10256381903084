<script>
import { API, Auth } from "aws-amplify";
import SpotPage from "./SpotPage.vue";
import HistoryPage from "./HistoryPage.vue";
import InformationPage from "./InformationPage.vue";
import SettingPage from "./SettingPage.vue";

export default {
  props: ["user", "signOut"],
  components: {
    SpotPage,
    HistoryPage,
    InformationPage,
    SettingPage,
  },
  data() {
    return {};
  },
  async created() {
    const token = (await Auth.currentSession()).idToken.jwtToken;

    let req_data = {
      headers: {
        Authorization: token,
      },
      body: {
        action: "sample",
        param1: "sample data",
      },
    };

    console.debug(req_data);

    API.post("main", "/action", req_data)
      .then((res) => {
        console.debug(res);
      })
      .catch((res) => {
        console.error("エラー発生：", res);
      });
  },
};
</script>

<template>
  <div>
    <ul
      class="nav nav-pills sticky-top border p-2"
      id="pills-tab"
      role="tablist"
      style="background-color: #788b91"
    >
      <span class="h3" style="color: white">電気自動車放電ステーション</span>
    </ul>

    <div class="container tab-content border" id="pills-tabContent">
      <div
        class="tab-pane fade show active p-1"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <SpotPage />
      </div>
      <div
        class="tab-pane fade"
        id="pills-profile"
        role="tabpanel"
        aria-labelledby="pills-profile-tab"
      >
        <HistoryPage :user="user" />
      </div>
      <div
        class="tab-pane fade"
        id="pills-contact"
        role="tabpanel"
        aria-labelledby="pills-contact-tab"
      >
        <InformationPage />
      </div>

      <div
        class="tab-pane fade"
        id="pills-setting"
        role="tabpanel"
        aria-labelledby="pills-setting-tab"
      >
        <SettingPage :signOut="signOut" />
      </div>

      <ul
        class="nav nav-pills fixed-bottom border p-2"
        id="pills-tab"
        role="tablist"
        style="background-color: #788b91"
      >
        <li class="nav-item" role="presentation">
          <button
            class="nav-link btn-foot active btn-success mx-1 glyphicon glyphicon-cloud-download"
            id="pills-home-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-home"
            type="button"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
            style="width: 80px"
          >
            <i class="bi bi-lightning-charge-fill"></i><br />
            スポット
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link btn-foot mx-1"
            id="pills-profile-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-profile"
            type="button"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="false"
            style="width: 80px"
          >
            <i class="bi bi-collection-fill"></i><br />
            放電履歴
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link btn-foot mx-1"
            id="pills-contact-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-contact"
            type="button"
            role="tab"
            aria-controls="pills-contact"
            aria-selected="false"
            style="width: 80px"
          >
            <i class="bi bi-info-circle-fill"></i><br />
            お知らせ
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link btn-foot mx-1"
            id="pills-setting-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-setting"
            type="button"
            role="tab"
            aria-controls="pills-setting"
            aria-selected="false"
            style="width: 80px"
          >
            <i class="bi bi-gear-fill"></i><br />
            設定
          </button>
        </li>
      </ul>
    </div>
    <div style="height: 150px"></div>
  </div>
</template>
