<script>
    import { API, Auth } from 'aws-amplify';

    export default {
        components: {
        },
        data() {
            return {
                groups: [],
                group_id: "",
                spots: [],
                spot_id: "",
                device_id: "",
                spot_info: {},
                group_info: {},
                state: 0,
                qr: null,
                last_operation: 0,
                last_operation_before: 0,
                start: false,
                stop: false,
                requesting: false,
                message: "",
            }
        },
        async created() {
            if (this.$route.query.qr) {
                // URLにQRコードのパラメータが付いている場合
                try {
                    // URLクエリの取得（base64の情報）
                    this.qr = JSON.parse(atob(this.$route.query.qr))
                    console.log("qr-data:", this.qr);
                    this.group_id = this.qr.group_id;
                    this.spot_id = this.qr.spot_id;
                    localStorage.setItem('group_id', this.group_id);
                    localStorage.setItem('spot_id', this.spot_id);

                    this.getSpotIds(this.spot_id);
                }
                catch (e) {
                    console.log(e);
                }
            } else if (this.$route.query.group_id) {
                //パラメータ指定のURLクエリの場合
                this.qr = {
                    group_id: this.$route.query.group_id,
                    spot_id: this.$route.query.spot_id,
                }

                this.group_id = this.qr.group_id;
                this.spot_id = this.qr.spot_id;
                localStorage.setItem('group_id', this.group_id);
                localStorage.setItem('spot_id', this.spot_id);

                this.getSpotIds(this.spot_id);

            } else {
                // URLクエリが存在しない場合は、最後に表示したスポットを表示する
                this.group_id = localStorage.getItem('group_id')
                this.spot_id = localStorage.getItem('spot_id')

                if (this.spot_id) {
                    this.getSpotIds(this.spot_id);
                }
            }

            const token = (await Auth.currentSession()).idToken.jwtToken;

            // 事業所の一覧を表示する
            let req_data = {
                headers: {
                    Authorization: token
                },
                body: {
                    action: "get_group_list"
                }
            };

            console.debug(req_data);

            API.post('main', '/action', req_data)
                .then((res) => {
                    console.debug(res);
                    this.groups = res;

                    // 事業所一覧に初期値の事業所を選択する
                    this.setGroupInfo();
                })
                .catch((res) => {
                    console.error("エラー発生：", res);
                });
            
            // ５秒周期でスポット情報を更新する
            setInterval(() => {
                if(this.group_id != "" && this.spot_id != "") {
                    console.log("exec polling:",this.group_id ,this.spot_id);
                    this.getSpotInfo();
                } else {
                    console.log("not polling:",this.group_id ,this.spot_id);
                }
            }, 5000);

        },
        methods: {
            initDisplay() {
                this.isStart();
                this.isStop();
                this.isRequesting();
                this.getMessage();
            },
            setGroupInfo() {
                // 事業所を選択する
                for (let group of this.groups) {
                    if (group.group_id == this.group_id) {
                        this.group_info = group;
                    }
                }
            },
            async getSpotIds(spot_id = null) {
                if (typeof spot_id != 'string') {
                    spot_id = null;
                }

                // 事業所を選択する
                this.setGroupInfo();

                // 選択した事業所IDのスポット一覧を取得する
                const token = (await Auth.currentSession()).idToken.jwtToken;
                localStorage.setItem('group_id', this.group_id);
                localStorage.setItem('spot_id', this.spot_id);

                let req_data = {
                    headers: {
                        Authorization: token
                    },
                    body: {
                        action: "get_spot_list",
                        group_id: this.group_id
                    }
                };

                console.log(req_data);

                API.post('main', '/action', req_data)
                    .then((res) => {
                        console.log("get_spot_list:", res);
                        this.clear();
                        this.spots = res;

                        if (spot_id != null) {
                            this.spot_id = spot_id;
                            this.getSpotInfo();
                        }
                    })
                    .catch((res) => {
                        console.error("エラー発生：", res);
                    });
            },
            async getSpotInfo() {
                // 選択した事業所ID・スポットIDをローカルストレージに保存して、次回アクセス時に復元できるようにする
                localStorage.setItem('group_id', this.group_id);
                localStorage.setItem('spot_id', this.spot_id);

                // スポットのデバイスIDを取得する
                for (let spot of this.spots) {
                    if (spot.spot_id == this.spot_id) {
                        this.device_id = spot.device_id;
                        break;
                    }
                }

                if(this.group_id == ""){
                    return;
                }

                // スポットの情報を取得する
                const token = (await Auth.currentSession()).idToken.jwtToken;

                let req_data = {
                    headers: {
                        Authorization: token
                    },
                    body: {
                        action: "get_spot_info",
                        group_id: this.group_id,
                        spot_id: this.spot_id,
                        device_id: this.device_id,
                    }
                };

                console.debug(req_data);

                API.post('main', '/action', req_data)
                    .then((res) => {
                        console.debug("get_spot_info:", res);
                        this.spot_info = res;
                        if(process.env.VUE_APP_CHARGE_KARI=="kari") {
                            this.state = res.reported.charger.mode;
                        } else {
                            this.state = res.reported.charger.state;
                        }
                        
                        this.last_operation = 0;

                        // このスポットの最後に操作したユーザを取得する
                        this.lastUserHistory();
                        //this.initDisplay();
                    })
                    .catch((res) => {
                        console.error("エラー発生：", res);
                    });



            },
            async lastUserHistory() {
                // このスポットの最後に操作したユーザを取得する
                const token = (await Auth.currentSession()).idToken.jwtToken;

                if(process.env.VUE_APP_CHARGE_KARI=="kari") {
                    let req_data = {
                        headers: {
                            Authorization: token
                        },
                        body: {
                            action: "last_user_history_kari",
                            group_id: this.group_id,
                            spot_id: this.spot_id,
                            device_id: this.device_id,
                        }
                    };

                    console.debug(req_data);

                    API.post('main', '/action', req_data)
                        .then((res) => {
                            console.debug("last_user_history_kari:", res);
                            this.last_operation = res.last_operation;
                            this.initDisplay();
                        })
                        .catch((res) => {
                            console.error("エラー発生：", res);
                        });
                } else {
                    let req_data = {
                        headers: {
                            Authorization: token
                        },
                        body: {
                            action: "last_user_history",
                            group_id: this.group_id,
                            spot_id: this.spot_id,
                            device_id: this.device_id,
                        }
                    };

                    console.debug(req_data);

                    API.post('main', '/action', req_data)
                        .then((res) => {
                            console.debug("last_user_history:", res);
                            this.last_operation = res.last_operation;
                            this.initDisplay();
                        })
                        .catch((res) => {
                            console.error("エラー発生：", res);
                        });
                }

            },
            getStateName(code) {
                let state_list = {
                    "0": "空き（停止）",
                    "1": "待機中",
                    "2": "充電中",
                    "3": "放電中"
                }
                return state_list[code];
            },
            clear() {
                this.spot_id = "";
                this.device_id = "";
                this.spot_info = {};
                this.state = 0;
            },
            isStart() {
                if(process.env.VUE_APP_CHARGE_KARI=="kari") {
                    this.start = this.isStartKari();
                    return this.start;
                }

                return false;
            },
            isStop() {
                if(process.env.VUE_APP_CHARGE_KARI=="kari") {
                    this.stop = this.isStopKari();
                    return this.stop;
                }

                return false;
            },
            isRequesting() {
                if(process.env.VUE_APP_CHARGE_KARI=="kari") {
                    let last_operation_time = localStorage.getItem('last_operation_time');
                    if (last_operation_time == null) last_operation_time = 0;
                    let now = Date.now();
                    console.log("isRequesting:",now - last_operation_time, now, last_operation_time);

                    if(now - last_operation_time < 46000){
                        console.log("操作の抑止中");
                        this.requesting = true;
                        return this.requesting;
                    } else {
                        this.requesting = false;
                        return this.requesting;
                    }

                }

                // 放電指示中の表示判定
                if (this.last_operation == 4) {
                    this.requesting = true;
                    return this.requesting;
                }
                this.requesting = false;
                return this.requesting;
            },
            getMessage() {
                if(process.env.VUE_APP_CHARGE_KARI=="kari") {
                    this.message = this.getMessageKari();
                    return this.message;
                }

                return false;
            },
            isStartKari() {
                if (!this.isRequesting() && this.spot_info.reported) {
                    if (this.qr != null && this.qr.group_id == this.group_id && this.qr.spot_id == this.spot_id) {
                        if (this.spot_info.reported.charger.mode == 0 || this.spot_info.reported.charger.mode == 1) {
                            console.log("isStartKari:true");
                            return true
                        }
                    }
                }

                console.log("isStartKari:false");
                return false;
            },
            isStopKari() {
                if (!this.isRequesting() && this.spot_info.reported) {
                    console.log("isStopKari:", this.last_operation, this.spot_info.reported.charger.mode)
                    if (this.spot_info.reported.charger.mode == 3) {
                        if (this.last_operation == 1) {
                            console.log("isStopKari:true");
                            return true
                        }
                    }
                }
                console.log("isStopKari:false");
                return false;
            },
            getMessageKari() {
                // 充電操作に関するヘルプメッセージを表示
                if (!this.spot_info.reported) {
                    return "";
                }

                console.log("getMessageKari:", this.spot_info.reported.charger.mode,this.last_operation,this.spot_info.reported.charger.state )

                const MESSAGE_CHARGE_OK = "放電可能です。放電をする場合は、プラグを車に差し込み、スポットのQRコードを読み込んでください";
                const MESSAGE_CHARGE_DONE = "放電が完了しました。";
                const MESSAGE_CHARGE_NG = "このスポットは、他の方が利用中のため使用できません。";
                const MESSAGE_REQESTING = "スポットに指示中です。動作までに時間がかかる場合があります。";

                if (this.isRequesting()) {
                    return MESSAGE_REQESTING;
                } else if (this.spot_info.reported.charger.mode == 0) {
                    return MESSAGE_CHARGE_OK;
                } else if (this.spot_info.reported.charger.mode == 1) {
                    if (this.last_operation == 0) {
                        if (this.qr == null) {
                            return MESSAGE_CHARGE_OK;
                        }

                    } else if (this.last_operation == 1) {
                        return MESSAGE_CHARGE_DONE;

                    } else if (this.last_operation == 3) {
                        return MESSAGE_CHARGE_NG;
                    }
                } else if (this.spot_info.reported.charger.mode == 3) {
                    if (this.last_operation == 0) {
                        return MESSAGE_CHARGE_NG;
                    } else if (this.last_operation == 2) {
                        return MESSAGE_CHARGE_NG;
                    }
                }

                return "";
            },
            async contoleCharge(mode) {
                // 操作前の確認メッセージ
                let message = "";
                if (mode == 0) {
                    message = "放電を終了しますか？";
                } else if (mode == 3) {
                    message = "放電を開始しますか？";
                }

                if (!confirm(message)) {
                    return;
                }

                this.last_operation_before = this.last_operation ;
                this.last_operation = 4;
                localStorage.setItem('last_operation_time', Date.now());
                this.initDisplay();

                const token = (await Auth.currentSession()).idToken.jwtToken;

                // 充電操作指示のリクエスト
                let req_data = {
                    headers: {
                        Authorization: token
                    },
                    body: {
                        action: "control_charge",
                        group_id: this.group_id,
                        spot_id: this.spot_id,
                        device_id: this.device_id,
                        mode: mode,
                    }
                };

                console.debug(req_data);

                API.post('main', '/action', req_data)
                    .then((res) => {
                        console.debug("control_charge:", res);
                        
                        // 放電指示後は、放電指示中を表示する
                        this.last_operation = 4;
                        localStorage.setItem('last_operation_time', Date.now());
                        this.initDisplay();
                    })
                    .catch((res) => {
                        this.last_operation = this.last_operation_before
                        localStorage.setItem('last_operation_time', Date.now()-46000);
                        this.initDisplay();
                        console.error("エラー発生：", res);
                        alert("放電操作に失敗しました。");
                    });

            }
        }
    }
</script>

<template>
    <div>
        <span class="h4">スポット</span>
        <select class="form-select" v-model="group_id" @change="getSpotIds">
            <option v-for="group in groups" :key="group.group_id" :value="group.group_id">{{group.group_name}}</option>
        </select>
        <select class="form-select" v-model="spot_id" @change="getSpotInfo">
            <option v-for="spot in spots" :key="spot.device_id" :value="spot.spot_id">スポット No.{{spot.spot_id}}</option>
        </select>
        <br>

        <div v-if="device_id != ''" class="border rounded px-3" style="background-color: #e4e4e8;">
            <div class="my-4 border-bottom border-dark border-2">
                <span class="">所在地</span>
                <div class="float-end">{{group_info.address}}</div>
            </div>
            <div class="my-4 border-bottom border-dark border-2">
                <span class="row">
                    <span class="col m-0" style="">営業時間</span>
                    <span class="col text-end m-0" style="white-space:pre">{{group_info.business_hours}}</span>
                </span>
            </div>
            <div class="my-4 border-bottom border-dark border-2">
                <span class="">スポットNo.</span>
                <div class="float-end">No.{{spot_id}}</div>
            </div>
            <div class="my-4 border-bottom border-dark border-2">
                <span class="">利用状況</span>
                <div class="float-end">{{getStateName(state)}}</div>
            </div>
            <div class="my-3">
                <button v-if="start" class="btn btn-primary m-size inline" @click="contoleCharge(3)">放電を開始する</button>
                <button v-if="stop" class="btn btn-warning m-size inline" @click="contoleCharge(0)">放電を終了する</button>
                <button v-if="requesting" class="btn btn-secondary m-size inline">操作指示中</button>
                <div class="mt-3">{{message}}</div>
            </div>
        </div>
    </div>
</template>