// console.debug = function(){/* NOP */};
// console.log = function(){/* NOP */};
// console.info = function(){/* NOP */};
// console.warn = function(){/* NOP */};
// console.error = function(){/* NOP */};

import { createApp } from 'vue'
import App from './App.vue'

//import Amplify from 'aws-amplify'
import Amplify, * as AmplifyModules from 'aws-amplify'
import aws_exports from './aws-exports';
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

import { vocabularies } from './conf/dictionary.js'

aws_exports.API = {
  endpoints : [
    {
        name: "main",
        endpoint: process.env.VUE_APP_API_ENDPOINT
    }
  ]
}

Amplify.configure(aws_exports);
applyPolyfills().then(() => {
  defineCustomElements(window);
});

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min.js"

// authority
//createApp(App).mount('#app')

import router from './router.js';
const app = createApp(App);
app.use(router);

app.config.errorHandler = function (err, vm, info) {
  console.log(err, vm, info);
  // 静的ページにリダイレクト
  window.location.href = '/error.html'
}

app.mount('#app');

AmplifyModules.I18n.putVocabularies(vocabularies)

