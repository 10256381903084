<script setup>
import {
  Authenticator,
  AuthenticatorSignUpFormFields,
  useAuthenticator,
} from "@aws-amplify/ui-vue";
</script>

<script>
import "@aws-amplify/ui-vue/styles.css";

export default {
  name: "App",
  components: {},
  data() {
    return {
      page: "top",
      evowner: "",
    };
  },
  mounted: () => {
    document.title="電気自動車放電ステーション"
  }
};

import { toRefs } from "vue";

const { validationErrors } = toRefs(useAuthenticator());

const services = {
  async validateCustomSignUp(formData) {
    console.log("##fromData:", formData);

    let res = {}
    if (!formData.acknowledgement) {
      res.acknowledgement = "※アカウントを作成するには、利用規約に同意してください。";
    }
    if (!formData.EVOwner) {
      res.EVOwner = "※アカウントを作成するには、EVを所有している必要があります。";
    }
    if (!formData["custom:pv_capacity"]) {
      res.pv_capacity = "※アカウントを作成するには、太陽光発電を所有している必要があります。";
    }
    return res;
  },
};
</script>

<style>
.inline {
  display: inline !important;
  margin-left: 1rem;
}

.nav-pills .nav-link.active {
  background-color: white !important;
  color: black !important;
}

.nav-pills .nav-link {
  background-color: #b9c1c5 !important;
  color: black !important;
}

.btn-foot {
  font-size: 0.875rem;
  width: 85px;
  padding: 0.8rem 0.2rem !important;
}

input[name="confirmation_code"] {
  display: none;
}

footer
  button:first-child.amplify-button.amplify-button--primary.amplify-field-group__control.amplify-authenticator__font {
  display: none;
}

form[data-amplify-authenticator-resetpassword]
  > div
  > footer
  > button:first-child {
  display: flex !important;
}

form[data-amplify-authenticator-confirmresetpassword]
  > fieldset
  input[name="confirmation_code"] {
  display: flex !important;
}

form[data-amplify-authenticator-confirmresetpassword]
  > fieldset
  > footer
  > button:first-child {
  display: flex !important;
}

.bg-darkblue {
  background-color: darkblue;
}
</style>

<template>
  <div>
    <link
      rel="stylesheet"
      href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.0/font/bootstrap-icons.css"
    />
    <authenticator :services="services">
      <template v-slot:header>
        <h3 style="margin-top: 1rem; margin-bottom: 1rem; text-align: center">
          電気自動車放電ステーション
        </h3>
      </template>
      <template v-slot:footer>
        <br />
      <a target="_blank" href="/agreement.html">利用規約を表示する</a><br />
      <br />
      </template>
      <template v-slot:sign-up-fields>
        <authenticator-sign-up-form-fields />
        <div class="amplify-flex amplify-field-group">
          <div class="amplify-field-group__outer-start"></div>
          <div class="amplify-field-group__field-wrapper">
            <input
              data-amplify-input=""
              class="amplify-input amplify-field-group__control"
              id="amplify-field-888045"
              autocomplete="username"
              name="custom:pv_capacity"
              required
              type="number"
              step="0.01"
              placeholder="太陽光発電容量"
              aria-invalid="false"
            />
          </div>
          <p style="margin: auto 15px">kW</p>
        </div>
        <div class="text-danger">{{ validationErrors.pv_capacity }}</div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value="yes"
            id="EVOwner"
            name="EVOwner"
          />
          <label class="form-check-label" for="EVOwner">
            EV を所有している
          </label>
          <div class="text-danger">{{ validationErrors.EVOwner }}</div>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value="yes"
            id="acknowledgement"
            name="acknowledgement"
          />
          <label class="form-check-label" for="acknowledgement">
            利用規約に同意する
          </label>
          <div class="text-danger">{{ validationErrors.acknowledgement }}</div>
        </div>
        <a target="_blank" href="/agreement.html">利用規約</a>
      </template>
      <template v-slot="{ user, signOut }">
        <router-view :user="user" :signOut="signOut" />
      </template>
    </authenticator>
  </div>
</template>
