<script>
import { API, Auth } from "aws-amplify";

export default {
  props: ["user"],
  components: {},
  data() {
    return {
      history: [],
      pvCapacity: "",
      equipmentUtilizationRate: "",
    };
  },
  async created() {
    const token = (await Auth.currentSession()).idToken.jwtToken;

    await this.getEquipmentUtilizationRate(token);

    const userAttributes = await Auth.userAttributes(this.user);
    this.userAttributes = userAttributes;

    const pvCapacity = userAttributes.filter((attribute) => {
      if (attribute.Name == "custom:pv_capacity") {
        return attribute;
      }
    });
    if (pvCapacity[0]) {
      this.pvCapacity = pvCapacity[0].Value;
    }

    let req_data = {
      headers: {
        Authorization: token,
      },
      body: {
        action: "get_user_history",
      },
    };

    console.debug("get_user_history req:", req_data);

    API.post("main", "/action", req_data)
      .then((res) => {
        console.debug("get_user_history res:", res);
        this.history = res;
      })
      .catch((res) => {
        console.error("エラー発生：", res);
      });
  },
  methods: {
    async getEquipmentUtilizationRate(token) {
      let req_data = {
        headers: {
          Authorization: token,
        },
        body: {
          action: "get_equipment_utilization_rate",
        },
      };

      console.debug("get_equipment_utilization_rate req:", req_data);

      API.post("main", "/action", req_data)
        .then((res) => {
          console.debug("get_equipment_utilization_rate res:", res);
          this.equipmentUtilizationRate = res.equipment_utilization_rate;
        })
        .catch((res) => {
          console.error("エラー発生：", res);
        });
    },
  },
};
</script>

<template>
  <span class="h4">放電履歴</span>
  <div class="border rounded px-3" style="background-color: #e4e4e8">
    <div class="my-4 border-bottom border-dark border-2">
      <span class="">ユーザID </span>
      <div class="float-end">{{ user.attributes.profile }}</div>
    </div>
    <div class="my-4 border-bottom border-dark border-2">
      <span class="">太陽光発電容量 </span>
      <div class="float-end">{{ pvCapacity }} kW</div>
    </div>
    <div class="my-4 border-bottom border-dark border-2">
      <span class="">月あたりの上限放電量 </span>
      <div class="float-end">
        {{ Math.floor(Number(pvCapacity) * Number(equipmentUtilizationRate)) }}
        kWh
      </div>
    </div>
    <div class="my-4 border-bottom border-dark border-2">
      <span class="">履歴</span>
    </div>
    <table class="table table-bordered table-hover">
      <thead class="bg-darkblue bg-gradient text-white">
        <tr>
          <th scope="col" class="col-3"><span>放電</span><span>時間</span></th>
          <th scope="col" class="col-3"><span>放電</span><span>日時</span></th>
          <th scope="col" class="col-3">
            <span>（参考）</span><span>放電量</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item of history" :key="item.device_id">
          <td>
            {{
              Number(
                new Date(
                  `${item.start_time.split(" ")[0]} ${item.stop_time}`
                ).getTime() - new Date(item.start_time).getTime()
              ) /
              (60 * 1000)
            }}
            分
          </td>
          <td>
            <span>
            '{{ item.start_time.split(" ")[0].split("-")[0].slice(-2) }}/{{
               item.start_time.split(" ")[0].split("-")[1] }}/{{
               item.start_time.split(" ")[0].split("-")[2]
            }}
            {{ item.start_time.split(" ")[1] }} ~ {{ item.stop_time }}
            </span>
          </td>
          <td>
            <span>{{ Math.floor(item.discharge_wh / 100) / 10 }} kWh</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <br /><br /><br /><br /><br />
</template>

<style scoped>
span {
  display: inline-block;
  white-space: nowrap;
}
</style>
