<script>
    import ChangePasswod from "./ChangePasswod.vue"
    import ContactForm from "./ContactForm.vue"
    
    export default {
        props: ["user", "signOut"],
        components: {
            ChangePasswod,
            ContactForm,
        },
        data() {
            return {
                
            }
        },
    }
</script>

<template>
    <div>
        <br>
        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#changePassword">
            パスワード変更
        </button><br>
        <br>
        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#contactForm">
            お問い合わせ
        </button><br>
        <br>
        <button class="btn btn-primary" @click="signOut">ログアウト</button><br>
        <br>
        <ChangePasswod />
        <ContactForm />
    </div>
</template>