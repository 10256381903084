<script>
    import { Auth } from 'aws-amplify';

    export default {
        components: {
        },
        data() {
            return {
                old_password: "",
                new_password1: "",
                new_password2: ""
            }
        },
        created() {
            this.old_password = "";
            this.new_password1 = "";
            this.new_password2 = "";
        },
        methods: {
            changePassword() {
                console.log("password:", this.new_password1, this.new_password2);
                let is_error = false;
                const form = this.$refs.change_passsword_validation;
                //const forms = document.querySelectorAll('.change_passsword_validation');
                console.log("form:", form);
                if (!form.checkValidity()) {
                    console.warn("入力チェックエラー");
                    is_error = true;
                }
                form.classList.add('was-validated');

                if (!this.password_check()) {
                    is_error = true;
                }

                if (is_error) return;


                Auth.currentAuthenticatedUser()
                    .then(user => {
                        return Auth.changePassword(user, this.old_password, this.new_password1);
                    })
                    .then(() => {
                        alert("パスワード更新が完了しました。");
                        this.$refs.close.click();

                    })
                    .catch(err => {
                        console.log("パスワード更新に失敗：", err);

                        let message = "パスワード更新に失敗しました。\n" + err;
                        if (err == "NotAuthorizedException: Incorrect username or password.") {
                            message = "古いパスワードが間違っています。"
                        } else if (err == "LimitExceededException: Attempt limit exceeded, please try after some time.") {
                            message = "一定回数の入力エラーがありました。\nしばらくしてから実施してください。"
                        }
                        alert(message);
                    });
            },
            password_check() {
                if (this.new_password1 != this.new_password2) {
                    console.log("パスワード不一致")
                    this.$refs.new_password2.classList.add('is-invalid');
                    this.$refs.new_password2.classList.remove('is-valid');
                    return false;
                } else {
                    this.$refs.new_password2.classList.remove('is-invalid');
                    this.$refs.new_password2.classList.add('is-valid');
                    return true;
                }
            },
            close() {
                this.$refs.change_passsword_validation.classList.remove('was-validated');
                this.$refs.new_password2.classList.remove('is-invalid');

                this.old_password = "";
                this.new_password1 = "";
                this.new_password2 = "";

            }
        }
    }
</script>

<template>
    <div class="modal fade" id="changePassword" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="changePasswordLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="changePasswordLabel">パスワード変更</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close"
                        ref="close"></button>
                </div>
                <div class="modal-body">
                    <form class="change_passsword_validation" novalidate ref="change_passsword_validation">
                        <div>
                            <span>古いパスワード</span>
                            <input type="password" class="form-control" v-model="old_password" required />
                            <span class="invalid-feedback">必須項目です</span>
                        </div>
                        <br>
                        <div>
                            <span>新しいパスワード</span>
                            <input type="password" class="form-control" v-model="new_password1" required
                                pattern="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!-~]{8,}$"
                                v-on:input="password_check" />
                            <span class="invalid-feedback">8文字以上で英大小数を1つ以上含めてください。</span>
                        </div>
                        <br>
                    </form>
                    <div>
                        <span>新しいパスワード（確認）</span>
                        <input type="password" class="form-control" v-model="new_password2" ref="new_password2"
                            v-on:input="password_check" />
                        <span class="invalid-feedback">新しいパスワードと同じものを入力してください。</span>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                        @click="close">キャンセル</button>
                    <button type="button" class="btn btn-primary" @click="changePassword">変更</button>
                </div>
            </div>
        </div>
    </div>
</template>