<script>
    import { API, Auth } from 'aws-amplify';
    import setting from '../conf/setting.json'

    export default {
        components: {
        },
        data() {
            return {
                select_contact_type: setting.contact_type[0],
                contact_type: setting.contact_type,
                body: ""
            }
        },
        computed: {
            activateSubmit() {
                return (this.body.trim().length === 0)
            }
        },
        methods: {
            async send(){
                const token = (await Auth.currentSession()).idToken.jwtToken;

                // 事業所の一覧を表示する
                let req_data = {
                    headers: {
                        Authorization: token
                    },
                    body: {
                        action: "send_contact_mail",
                        contact_type: this.select_contact_type,
                        body: this.body
                    }
                };

                console.debug(req_data);

                API.post('main', '/action', req_data)
                    .then((res) => {
                        console.debug(res);

                        alert("問合せの送信が完了しました。")

                        this.$refs["close"].click()
                    })
                    .catch((res) => {
                        console.error("エラー発生：", res);
                    });
            },
            close() {
                this.reset()
            },
            reset() {
                this.select_contact_type = setting.contact_type[0]
                this.body = ""
            }
        }
    }
</script>

<template>
    <div class="modal fade" id="contactForm" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="contactFormLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="contactFormLabel">お問い合わせフォーム</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close"
                        ref="close"></button>
                </div>
                <div class="modal-body">
                    <span class="list">問合せ種別</span>
                    <select class="inline" v-model="select_contact_type">
                        <option v-for="option in contact_type" :key="option">{{ option }}</option>
                    </select><br>
                    <br>
                    問合せ内容<br>
                    <textarea v-model="body" style="width:100%; height: 15rem"></textarea>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                        @click="close">キャンセル</button>
                    <button type="button" class="btn btn-primary" v-bind:disabled="activateSubmit" @click="send">送信</button>
                </div>
            </div>
        </div>
    </div>
</template>