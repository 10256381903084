<script>
import { API, Auth } from "aws-amplify";

export default {
  components: {},
  data() {
    return {
      informations: [],
    };
  },
  async created() {
    const token = (await Auth.currentSession()).idToken.jwtToken;

    let req_data = {
      headers: {
        Authorization: token,
      },
      body: {
        action: "get_user_information",
      },
    };

    console.debug("get_user_information req:", req_data);

    API.post("main", "/action", req_data)
      .then((res) => {
        console.debug("get_user_information res:", res);
        this.informations = res;
      })
      .catch((res) => {
        console.error("エラー発生：", res);
      });
  },
};
</script>

<template>
  <div>
    <span class="h4">お知らせ</span>
    <div class="border rounded px-3" style="background-color: #e4e4e8">
      <div class="my-4" v-for="info of informations" :key="info.time">
        <div v-if="!info.is_delete">
          <div class="border-bottom border-dark border-2">
            <span class="">{{ info.time }}</span>
          </div>
          <pre style="white-space: pre-wrap">{{ info.message }}</pre>
        </div>
      </div>
      <br /><br /><br /><br /><br />
    </div>
  </div>
</template>
